import React, { Component } from 'react';
import gameFilter from '../../libs/gameFilter';

class ResultsList extends Component {

    render() {

        var itemsList = gameFilter(this.props.filterText, this.props.results, this.props.fuseSearch).map(function(item, index){
            return (
                <tr key={item.name}>
                    <td>{index+1}</td>
                    <td>{item.name}</td>
                    <td>{item.new}</td>
                    <td>{item.used}</td>
                </tr>
            );
        });
        
        return (
            
            <main>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Nazwa</th>
                                            <th>Nowa</th>
                                            <th>Używana</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {itemsList}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default ResultsList;
