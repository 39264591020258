import React, { Component } from 'react';

class Footer extends Component {

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <div className="small text-muted footer-info" >Wszystkie dane pochodzą z serwisu <a href="http://ultragames.pl" rel="noopener noreferrer" target="_blank">ultragames.pl</a>. <span data-ng-if="las_update">Aktualizacja danych: {this.props.update}.</span> v{this.props.version}.</div>
                    </div>
                </div>            
            </div>
        );
    }
}

export default Footer;
