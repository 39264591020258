import React, { Component } from 'react';

class Loader extends Component{

    render() {
        if(this.props.isLoading){
            return (
                <div className="loader text-center">
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>                    
                </div>            
            );
        }else{
            return (<div></div>);
        }
    }

}

export default Loader;