import React, { Component } from 'react';
import SearchBar from './components/searchBar/searchBar';
import ResultsList from './components/resultsList/resultsList';
import Loader from './components/loader/loader';
import Footer from './components/footer/footer';
import Fuse from 'fuse.js';
import './App.css';

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      searchText: '',
      gamesList: [],
      appVersion: "1.0.2",
      lastLoad: "b/d",
      isLoading: false,
      fuseSearch: null
    }

    this.updateGames = this.updateGames.bind(this);
    this.updateFilterText = this.updateFilterText.bind(this);
    this.updateIsLoading = this.updateIsLoading.bind(this);
  }

  updateFilterText(text){
    this.setState({
      searchText: text
    });
  }
  
  updateIsLoading(isLoading){
    this.setState({
      isLoading: isLoading
    });
  }

  updateGames(games, lastUpdateDate){
    var options = {
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,      
      keys: ['name'],
    }
    var fuse = new Fuse(games, options)

    this.setState({
      gamesList: games,
      lastLoad: lastUpdateDate,
      fuseSearch: fuse
    });
  }

  render() {
    var results_list = <ResultsList results={this.state.gamesList} filterText={this.state.searchText} fuseSearch={this.state.fuseSearch}/>;
    if(this.state.gamesList.length === 0){
      results_list = "";
    }

    return (
      <React.Fragment>
        <SearchBar updateGames={this.updateGames} updateFilterText={this.updateFilterText} updateIsLoading={this.updateIsLoading}/>
        {results_list}
        <Loader isLoading={this.state.isLoading}></Loader>
        <Footer version={this.state.appVersion} update={this.state.lastLoad}/>
      </React.Fragment>
    );
  }
}

export default App;
