function gameFilter(string_to_search, games_list, fuseSearch){
    if(string_to_search){
        // return fuseSearch.search(string_to_search);
        return games_list.filter(function(game){
            if (game.name.toLowerCase().includes(string_to_search.toLowerCase())) {
                return true;
            }
            return false;
        });
    }else{
        return games_list;
    }
}

export default gameFilter;