import React, { Component } from 'react';
import './searchBar.css';

class SearchBar extends Component {

    constructor(props) {
        super(props);
        this.state = {filterName: ''};

        this.filterByName = this.filterByName.bind(this);
        this.filterByPlatform = this.filterByPlatform.bind(this);
    }

    filterByName(event) {
        this.props.updateFilterText(event.target.value);
    }

    filterByPlatform(event) {
        var self = this;
        if(event.target.value){
            self.props.updateGames([], 'b/d');
            self.props.updateIsLoading(true);
            //'https://ultra.mirudesign.pl/data/' + event.target.value
            fetch('https://ultra.mirudesign.pl/data/' + event.target.value).then(function(res){
                return res.json();
            }).then(function(response_data){
                self.props.updateIsLoading(false);
                self.props.updateGames(response_data.games, response_data.date);
            });
        }else{
            self.props.updateIsLoading(false);
            self.props.updateGames([], 'b/d');
        }
    }

    render() {
        return (
            <header className="sticky-top search-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <select onChange={this.filterByPlatform} className="form-control">
                                <option value="">-- wybierz --</option>
                                <option value="ps4">PlayStation 4</option>
                                <option value="xbox">XboxOne</option>
                            </select>
                        </div>
                        <div className="col-6">
                            <input type="search" className="form-control" placeholder="Wpisz nazwę gry" onChange={this.filterByName} />
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default SearchBar;
